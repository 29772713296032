import React, { createContext, useState } from 'react'
import Notification from '../../models/Notification';


export const NotificationContext = createContext({
    notification: {} as Notification,
    showNotification: (notification: Notification) => { },
    hideNotification: () => { }
})


const NotificationContextComponent = ({ children }) => {
    
    const [activeNotification, setActiveNotification] = useState<Notification>(null);
    
    const showNotificationHandler = (notification: Notification) => {
        setActiveNotification(notification);
    }
    
    const hideNotificationHandler = () => {
        setActiveNotification(null);
    }
    
    const context = { notification: activeNotification, showNotification: showNotificationHandler, hideNotification: hideNotificationHandler }
    

    return (
        <NotificationContext.Provider value={context}>
            {children}
        </NotificationContext.Provider>
    )
}

export default NotificationContextComponent
