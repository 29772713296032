import React, { useContext } from 'react';
import { CSSTransition } from 'react-transition-group';
import Modal from 'react-modal';

import styles from '../../../styles/notifications.module.css';

import { NotificationContext } from '../../Layout/NotificationContextComponent';
import Link from 'next/link';

const NotificationComponent: React.FC = () => {
    const context = useContext(NotificationContext);

    if (context.notification == null) return <></>;
    const { status, message } = context?.notification;


    return (
        <CSSTransition
            in={message?.length > 0}
            timeout={300}
            classNames="dialog"
        >
            <Modal
                closeTimeoutMS={300}
                isOpen={message?.length > 0}
                onRequestClose={_ => context.hideNotification()}
                style={customStyles}
                ariaHideApp={false}
            >
                <div className={styles.modalContainer}>
                    <div className={styles.formContainer}>
                        <span className={styles.modalHelperText}>BOKIO FEL</span>
                        <span className={styles.modalTitle}>{message}</span>
                        <span className={styles.modalSubTitle}>
                            <strong>Om felet kvarstår, <Link href="/bokio/reset"><a onClick={_ => context.hideNotification()}>klicka här</a></Link> för att börja från början</strong> <br />
                        </span>
                    </div>
                    <div className={styles.closeContainer}>
                        <img onClick={_ => context.hideNotification()} src="/svg/close-black.svg" />
                    </div>
                </div>
            </Modal>
        </CSSTransition>
    )
}


const customStyles = {
    overlay: {
        zIndex: 4,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        width: '100vw',
        maxWidth: '650px',
        minHeight: '300px',
        borderRadius: '15px',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

export default NotificationComponent
