import React from 'react'

const TreeSection = () => {
    return (
        <section>
            <div className="container">
            <iframe src="https://bot.leadoo.com/bot/inpage.html?code=4LKstGkM" style={{border: 'none'}} width="100%" height="450px" ></iframe>
                <div className="row">
                    <div className="col-12">
                        <img className="img--responsive" src="/svg/trees.svg" alt="trees" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TreeSection
