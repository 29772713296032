const BASE_API = process.env.BASE_API;
export const PARTNER_OFFER_API = `${BASE_API}/Partner/Bokio/PartnerOffers`;
export const PARTNER_OFFER_SUBSCRIPE = `${BASE_API}/Partner/Bokio/Subscribe`;
export const PARTNER_SUBSCRIPTION_CALCULATION_API = `${BASE_API}/Partner/Bokio/SubscriptionCalculation`;
export const GET_PARTNER_OFFER_STEP2 = `${BASE_API}/Partner/Bokio/Subscribe-Step2`;
export const POST_PARTNER_OFFER_STEP2 = `${BASE_API}/Partner/Bokio/Subscribe-Step2`;
export const UPDATE_FIELDS_OFFER = `${BASE_API}/Partner/Bokio/Subscription-Entry`;
export const COMPLETE_OFFER = `${BASE_API}/Partner/Bokio/Subscription-Entry`;
export const SUBSCRIPTION_PREMIUM_CALCULATION = `${BASE_API}/Partner/Bokio/SubscriptionPremiumCalculation`;
export const GET_COMPANY_BY_ORG_NO = `${BASE_API}/Partner/Bokio/CompanySubscription`;
export const GET_COMPANY_BY_TOKEN = `${BASE_API}/Partner/Bokio/CompanyIdByOffer`;
export const SET_OPEN_PDF_API = `${BASE_API}/Partner/Bokio/DocumentDownloaded`;
export const SET_OPEN_PDF_API_COMPARER = `${BASE_API}/Partner/Bokio/Compare/DocumentDownloaded`;
export const SET_OPEN_PDF_API_EMPLOYER = `${BASE_API}/Partner/Bokio/Employer/DocumentDownloaded`;
export const COMPANY_COMPARE_API = `${BASE_API}/Partner/Bokio/CompaniesCompare`;
export const SINGLE_COMPANY_COMPARE_API = `${BASE_API}/Partner/Bokio/CompanyCompare`;
export const COMPARE_OFFER_INIT = `${BASE_API}/Partner/Bokio/CompanyCompare/Init`;
export const CHECK_ACTIVE_COMPANY = `${BASE_API}/Partner/Bokio/Company`;
export const GET_COMPANIES_LIST = `${BASE_API}/Partner/Bokio/ComparisonCompanies/List`;
export const SIGN_URL = `${BASE_API}/Partner/Bokio/Offer`;
export const POA_SIGN_URL = `${BASE_API}/Partner/Bokio/PowerOfAttorney`;
export const POA_SUBSCRIPE = `${BASE_API}/Partner/Bokio/CreatePowerOfAttorney`;



export const CONTACT_ME_FORM = `${BASE_API}/Partner/Bokio/ContactMe`;


export const CALCULATE_PARTNER_OFFER_BASED_ON_PERSONAL_NO = `${BASE_API}/Partner/Bokio/SubscriptionPremiumUpdate`;
export const OFFER_STATUS = `${BASE_API}/Partner/Bokio/Offer`;
export const COMPARE_OFFER_STATUS = `${BASE_API}/Partner/Bokio/Compare`;



export const ENTREPRENEUR_SIGN_PDF = '/pdf/pensure_5_satt_att_maximera_pensionen.pdf'
export const EMPLOYER_SIGN_PDF = '/pdf/Pensure_så_maximerar_du_dina_anställdas_pension.pdf'
export const COMPARER_SITE_PDF = '/pdf/pensure_viktigt_att_tanka_pa.pdf'


// Employer Routes
export const INIT_EMPLOYER = `${BASE_API}/Partner/Bokio/Employer/Init`;
export const COMPLETE_EMPLOYER = `${BASE_API}/Partner/Bokio/Employer`;
export const EMPLOYER_COMPANY_SAVE = `${BASE_API}/Partner/Bokio/Employer/AddOrganization`;
export const ADD_EMPLOYEE = `${BASE_API}/Partner/Bokio/Employer/UpdateEmployee`;

// sales support
export const SALES_INIT_EMPLOYER = `${BASE_API}/Partner/SalesSupport/Employer/Init`;
export const SALES_COMPLETE_EMPLOYER = `${BASE_API}/Partner/SalesSupport/Employer`;
export const SALES_EMPLOYER_COMPANY_SAVE = `${BASE_API}/Partner/SalesSupport/Employer/AddOrganization`;
export const SALES_ADD_EMPLOYEE = `${BASE_API}/Partner/SalesSupport/Employer/UpdateEmployee`;
export const PROMOTION_CODES = `${BASE_API}/Partner/SalesSupport/Employer/PromotionCodesList`;
export const AGE_GENDER_BY_SSN = `${BASE_API}/Partner/SalesSupport/AgeAndGenderBySsn/`;
export const EDIT_RISK_TAKER = `${BASE_API}/Partner/SalesSupport/Employer/EditRiskTaker/`;
export const EDIT_PROMOTION_CODE = `${BASE_API}/Partner/SalesSupport/Employer/EditPromotionCode/`;
export const SALES_SUPPORT_EMAIL_BCC = `${BASE_API}/Partner/SalesSupport/Employer/`;

