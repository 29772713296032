import React, { useState, useEffect } from 'react'
import Link from 'next/link';
import { useIntercom } from 'react-use-intercom';


const Header = () => {
    const [showMenu, setShowMenu] = useState(false)
    const { show } = useIntercom();

    const toggleMenu = (e) => {
        setShowMenu(prevState => !prevState);
    }


    useEffect(() => {
        if (showMenu) {
            document.body.classList.add('body--static');
        } else {
            document.body.classList.remove('body--static');
        }
    }, [showMenu])

    const menuClass = showMenu ? 'menu-dropdown--active' : '';

    return (
        <>
            <div className={`menu-dropdown ${menuClass}`} id="menu-dropdown">
                <div className="menu-dropdown__inner">
                    <div className="row">
                        <div className="col-6">
                            <a href="/" className="logo__link">
                                <img src="/svg/logo.svg" alt="logo" className="logo__img mobile-menu__link" />
                            </a>
                        </div>
                        <div onClick={e => toggleMenu(e)} className="col-6 text-right">
                            <img className="menu-dropdown-trigger j-menu-trigger mr-3" src="/svg/close-grey.svg" width="24" height="24" alt="" />
                        </div>
                    </div>
                    <div>
                        <ul className="mobile-menu mobile-menu--top list--reset">
                            <li className="mobile-menu__item">
                                <a className="mobile-menu__link" href="https://pensure.se/pris" target="_blank">Pris</a>
                            </li>
                            <li className="mobile-menu__item">
                                <a className="mobile-menu__link" href="https://pensure.se/kontakt" target="_blank">Kontakt</a>
                            </li>
                            <li className="mobile-menu__item">
                                <a className="mobile-menu__link" href="https://pensure.se/faq" target="_blank">Frågor och svar</a>
                            </li>
                            <li className="mobile-menu__item">
                                <a className="mobile-menu__link" href="https://pensure.se/kunskapsbanken" target="_blank">Kunskapsbanken</a>
                            </li>
                        </ul>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mobile-menu--bottom">
                            <div className="heading__pre-title">INFORMation</div>
                            <div className="mobile-menu--bottom-column">
                                <ul className="mobile-menu list--reset">
                                    <li className="mobile-menu__item">
                                        <a className="mobile-menu__link" href="https://pensure.se/vi-bakom-pensure" target="_blank">Vi bakom Pensure</a>
                                    </li>
                                    <li className="mobile-menu__item">
                                        <a className="mobile-menu__link" href="https://pensure.se/var-resa" target="_blank">Vår resa</a>
                                    </li>
                                    <li className="mobile-menu__item">
                                        <a className="mobile-menu__link" href="https://pensure.se/faq" target="_blank">Frågor och svar</a>
                                    </li>
                                    <li className="mobile-menu__item">
                                        <a className="mobile-menu__link" href="https://pensure.se/aktuellt" target="_blank">Aktuellt från Pensure</a>
                                    </li>
                                    <li className="mobile-menu__item">
                                        <a className="mobile-menu__link" href="https://pensure.se/kunskapsbanken" target="_blank">Kunskapsbanken</a>
                                    </li>
                                    <li className="mobile-menu__item">
                                        <a className="mobile-menu__link" href="https://pensure.se/kontakt" target="_blank">Kontakt</a>
                                    </li>
                                    <li className="mobile-menu__item">
                                        <a className="mobile-menu__link" href="https://pensure.se/hallbarhet" target="_blank">Hållbarhet</a>
                                    </li>
                                    <li className="mobile-menu__item">
                                        <a className="mobile-menu__link" href="https://pensure.se/forkopsinformation" target="_blank">Förköpsinformation</a>
                                    </li>
                                    <li className="mobile-menu__item">
                                        <a className="mobile-menu__link" href="https://pensure.se/partners" target="_blank">Bli partner</a>
                                    </li>
                                    <li className="mobile-menu__item">
                                        <a className="mobile-menu__link" href="https://press.pensure.se/" target="_blank">Press</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 mobile-menu--bottom">
                            <div className="heading__pre-title">Villkor</div>
                            <div className="mobile-menu--bottom-column">
                                <ul className="mobile-menu list--reset">
                                    <li className="mobile-menu__item">
                                        <a className="mobile-menu__link" href="https://pensure.se/nojdhet" target="_blank">Om du inte är nöjd?</a>
                                    </li>
                                    <li className="mobile-menu__item">
                                        <a className="mobile-menu__link" href="https://pensure.se/integritetspolicy" target="_blank">Din integritet</a>
                                    </li>
                                    <li className="mobile-menu__item">
                                        <a className="mobile-menu__link" href="https://pensure.se/anvandarvillkor" target="_blank">Användarvillkor</a>
                                    </li>
                                    <li className="mobile-menu__item">
                                        <a className="mobile-menu__link" href="https://pensure.se/juridisk-tillsyn" target="_blank">Juridisk tillsyn</a>
                                    </li>
                                    <li className="mobile-menu__item">
                                        <a className="mobile-menu__link" href="https://pensure.se/ersattningar" target="_blank">Våra ersättningar</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <header className="page-header j-page-header page-header--scrolled">
                <div className="container container--wide">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-auto">
                            <div className="logo">
                                <Link href="/bokio">
                                    <a className="logo__link">
                                        <img src="/svg/logo.svg" alt="logo" className="logo__img" />
                                    </a>
                                </Link>
                            </div>
                        </div>
                        <div className="col-auto d-none d-md-flex justify-content-center">
                            <ul className="header-menu list--reset">
                                <li className="header-menu__item">
                                    <a className="header-menu__link" href="https://pensure.se/pris" target="_blank">Pris</a>
                                </li>
                                <li className="header-menu__item">
                                    <a className="header-menu__link" href="https://pensure.se/kontakt" target="_blank">Kontakt</a>
                                </li>
                                <li className="header-menu__item">
                                    <a className="header-menu__link" href="https://pensure.se/faq" target="_blank">Frågor och svar</a>
                                </li>
                                <li className="header-menu__item">
                                    <a className="header-menu__link" href="https://pensure.se/kunskapsbanken" target="_blank">Kunskapsbanken</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-auto d-flex align-items-center justify-content-end">
                            <div className="wrapper">
                                <button onClick={e => show()} className="button button--outline button--md-small j-chat-btn">Chatta</button>
                            </div>
                            <div className="menu-trigger j-menu-trigger" onClick={(e) => toggleMenu(e)}>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header;
