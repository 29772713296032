import '../styles/globals.css'
import Layout from '../components/Layout/Layout';
import { IntercomProvider } from 'react-use-intercom';
import React from 'react';
import NotificationContextComponent from '../components/Layout/NotificationContextComponent';

function MyApp({ Component, pageProps }) {
  return (
    <IntercomProvider autoBoot appId={process.env.INTERCOM_APP_ID}>
      <NotificationContextComponent>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </NotificationContextComponent>
    </IntercomProvider>
  );
}

export default MyApp;
