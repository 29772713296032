import React from 'react'

const Footer = () => {
    return (
         <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 col-md-4 col-lg-3">
                        <h5 className="footer__title">Tjänste&shy;pension utan krångel</h5>
                        <p className="footer__text">
                            Pensure erbjuder en smart digital plattform för enkel uppstart och administration av hållbara och prisvärda tjänste&shy;pensioner helt utan försäkringsavgifter.
                        </p>
                    </div>

                    <div className="mt-30 mt-sm-0 col-sm-6 col-md-4 col-lg-3 col-xl-2 offset-xl-1">
                        <h5 className="footer__title">Information</h5>
                        <ul className="footer-list list--reset">
                            <li className="footer-list__item">
                                <a className="footer-list__link" href="https://pensure.se/vi-bakom-pensure" target="_blank">Vi bakom Pensure</a>
                            </li>
                            <li className="footer-list__item">
                                <a className="footer-list__link" href="https://pensure.se/var-resa" target="_blank">Vår resa</a>
                            </li>
                            <li className="footer-list__item">
                                <a className="footer-list__link" href="https://pensure.se/faq" target="_blank">Frågor och svar</a>
                            </li>
                            <li className="footer-list__item">
                                <a className="footer-list__link" href="https://pensure.se/aktuellt" target="_blank">Aktuellt från Pensure</a>
                            </li>
                            <li className="footer-list__item">
                                <a className="footer-list__link" href="https://pensure.se/kunskapsbanken" target="_blank">Kunskapsbanken</a>
                            </li>
                            <li className="footer-list__item">
                                <a className="footer-list__link" href="https://pensure.se/kontakt" target="_blank">Kontakt</a>
                            </li>
                            <li className="footer-list__item">
                                <a className="footer-list__link" href="https://pensure.se/hallbarhet" target="_blank">Hållbarhet</a>
                            </li>
                            <li className="footer-list__item">
                                <a className="footer-list__link" href="https://pensure.se/forkopsinformation" target="_blank">Förköpsinformation</a>
                            </li>
                            <li className="footer-list__item">
                                <a className="footer-list__link" href="https://pensure.se/partners" target="_blank">Bli partner</a>
                            </li>
                            <li className="footer-list__item">
                                <a className="footer-list__link" href="https://press.pensure.se/" target="_blank">Press</a>
                            </li>
                        </ul>
                    </div>

                    <div className="mt-30 mt-md-0 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                        <h5 className="footer__title">Villkor</h5>
                        <ul className="footer-list list--reset">
                            <li className="footer-list__item">
                                <a className="footer-list__link" href="https://pensure.se/nojdhet" target="_blank">Om du inte är nöjd?</a>
                            </li>
                            <li className="footer-list__item">
                                <a className="footer-list__link" href="https://pensure.se/integritetspolicy" target="_blank">Din integritet</a>
                            </li>
                            <li className="footer-list__item">
                                <a className="footer-list__link" href="https://pensure.se/anvandarvillkor" target="_blank">Användarvillkor</a>
                            </li>
                            <li className="footer-list__item">
                                <a className="footer-list__link" href="https://pensure.se/juridisk-tillsyn" target="_blank">Juridisk tillsyn</a>
                            </li>
                            <li className="footer-list__item">
                                <a className="footer-list__link" href="https://pensure.se/ersattningar" target="_blank">Våra ersättningar</a>
                            </li>
                        </ul>
                    </div>


                    <div className="mt-30 mt-lg-0 col-sm-6 col-lg-3 offset-xl-1">
                        <h5 className="footer__title">Kontakt</h5>
                        <div className="footer-contacts">
                            <div className="footer-contacts__item">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="20" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M0 7c0-3.87 3.13-7 7-7s7 3.13 7 7c0 5.25-7 13-7 13S0 12.25 0 7zm4.5 0a2.5 2.5 0 005 0 2.5 2.5 0 00-5 0z" fill="#5DC888" /></svg>
                                <span className="font--medium color--black">Pensure AB</span>
                                <span>Box 55607<br />102 14 Stockholm</span>
                            </div>

                            <div className="footer-contacts__item">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none"><path d="M3.62 7.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V17c0 .55-.45 1-1 1C7.61 18 0 10.39 0 1c0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z" fill="#5DC888" /></svg>
                                <a className="footer-contacts__link font--medium color--black" href="tel:0812097272">08-12 09 72 72</a>
                            </div>

                            <div className="footer-contacts__item">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M2 0h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2L.01 2C.01.9.9 0 2 0zm8 9l8-5V2l-8 5-8-5v2l8 5z" fill="#5DC888" /></svg>
                                <a className="footer-contacts__link font--medium color--black" href="mailto:info@pensure.se">info@pensure.se</a>
                            </div>
                        </div>

                        
                    </div>

                </div>

                <div className="row mt-30">
                    <div className="col-12">
                        <div className="logo">
                            <a href="/" className="logo__link">
                                <img src="/svg/logo.svg" alt="logo" className="logo__img" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
