import Head from 'next/head'
import React, { createContext, useContext, useState } from 'react'
import EmailInputComponent from '../organisms/EmailInputComponent'
import TreeSection from '../atoms/General/TreeSection'
import Footer from '../molecules/Footer';
import Header from '../organisms/Header'
import NotificationComponent from '../atoms/General/NotificationComponent';
import { NotificationContext } from './NotificationContextComponent';
import axios from 'axios';

const Layout = ({ children }) => {
    const notificationContext = useContext(NotificationContext);

    axios.interceptors.response.use(request => {
      return request;
    }, error => {
      console.log(error)
      if(!error || !error.response) {
        notificationContext.showNotification({ status: 'danger', message: 'Back-end is not working.' });
        return Promise.reject(error);
      }

      if(error.response.status === 400 || error.response.status === 406) {
        notificationContext.showNotification({ status: 'danger', message: error.response.data.Error });
        return Promise.reject(error);
      }

      if(error.response.status === 500) {
        notificationContext.showNotification({ status: 'danger', message: 'Ett okänt fel har inträffat. Försök igen.' });
      }
      return Promise.reject(error);
    });

    return (
        <>
            <Head>
                <title>Pensure</title>
                <link rel="icon" href="/favicon.ico" />
            </Head>
            <Header />
            <div className="subpage">
                <NotificationComponent />
                {children}
            </div>
            <EmailInputComponent />
            <TreeSection />
            <Footer />
        </>
    )
}

export default Layout
