import axios from 'axios';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import { CONTACT_ME_FORM } from '../../config';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

const schema = yup.object().shape({
    email: yup.string().email("E-post är inte giltigt").required("E-Post måste anges").typeError("E-Post måste anges"),
    phone: yup.string()
});

type Inputs = {
    email: string;
    phone: string;
}

const EmailInputComponent = () => {
    const { show } = useIntercom();
    const router = useRouter();

    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');

    const { register, handleSubmit, getValues, setValue, trigger, watch, formState, control } = useForm<Inputs>({ defaultValues: {email: '', phone: ''}, resolver: yupResolver(schema) });

    const { errors } = formState;


    const Send = async () => {
        var model = Object.assign({}, getValues());
        const result = axios.post(`${CONTACT_ME_FORM}`, { 
            email: model.email, phone: model.phone, 
            offerId: localStorage.getItem('SUBSCRIPTION_OFFER_ID') ?? localStorage.getItem('SUBSCRIPTION_COMPARE_OFFER_ID') ?? ''
        });
        router.push('/bokio/kontakta-mig-tack');
    }

    return (
        <section id="contact-form" className="section">
            <div className='container'>
                <div className="row">
                    <div className="col-12">
                        <div className="contact-block">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <img src="/img/team.jpg" alt="team" className="img--responsive" />
                                </div>
                                <div className="col-lg-6">
                                    <div className="contact-block__inner">
                                        <div className="heading text-left mb-30">
                                            <span className="heading__pre-title">Prata med en expert</span>
                                            <h2 className="heading__title">Bli kontaktad eller chatta direkt</h2>
                                        </div>
                                            <input type="email" className="form__field" { ...register('email')} 
                                                placeholder="Email" data-valid="required" />
                                            { errors.email && <div className="text-danger">{errors.email?.message}</div>}
                                            <input type="text" className="form__field" { ...register('phone') }
                                                placeholder="Phone"  />
                                            <div className="form__fieldset d-flex flex-column flex-sm-row">
                                                <button type="button" onClick={_ => show()} className="j-chat-btn button button--dark mt-sm-0 mt-3">Öppna chatten</button>
                                                <button onClick={handleSubmit(_ => Send())} className="mt-sm-0 mt-4 button button--blue">Kontakta mig</button>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EmailInputComponent;
